import { cloneDeep }           from "lodash";
import OptionsBase from "./OptionsBase";

export default class StackedChart extends OptionsBase {

	_options;
	optionsSample = {
		title     : {},
		chart     : {
			type      : "line",
			animations: {
				enabled: false,
			},
			toolbar: {
			  show: true,
			  tools: {
			    zoom: true,
			    zoomin: true,
			    zoomout: true,
			    pan: true,
			    reset: true,
			    download: false,
			  }
			},
			// stacked: true,
			// events : {
			//   selection: function(chart, e) {
			//     console.log(new Date(e.xaxis.min));
			//   },
			// },
		},
		colors    : [ "#008FFB", "#a2d0fb", "#00a71f", "#b7f4ca" ],
		dataLabels: {
			enabled: false,
		},
		stroke    : {
			width    : [ 7, 2, 7, 2 ],
			curve    : "smooth",
			dashArray: [ 0, 8, 0, 8 ],
		},
		legend    : {
			position       : "top",
			horizontalAlign: "left",
		},
		yaxis     : {
			// axisTicks : {
			//   show: true,
			// },
			// axisBorder: {
			//   show : true,
			//   color: "#008FFB",
			// },
			// labels    : {
			//   style: {
			//     color: "#008FFB",
			//   },
			// },
		},
		grid      : {
			row: {
				colors: [ "#fff", "#f2f2f2" ],
			},
		},
		xaxis     : {
			// type: "datetime",
		},
		responsive: [ {
			breakpoint: 667,
			options   : {
				chart : {
					height: 300,
				},
				legend: {
					position: "bottom",
				},
			},
		} ],
	};

	/**
	 *
	 * @param title {string}
	 */
	constructor(title) {
		super();
		this._options            = cloneDeep(this.optionsSample);
		this._options.title.text = title;
		this.setLocaleSettings();
	}

	/**
	 *
	 * @param val {Array<Any>}
	 */
	set xAxisLabels(val) {
		this._options.xaxis = this._generateXAxis(val);
	}

	/**
	 *
	 * @param xAxisLabels {Array<Any>}
	 * @private
	 * @return {Object}
	 */
	_generateXAxis(xAxisLabels) {
		return {
			type      : "datetime",
			categories: xAxisLabels,
		};
	}

}
