import BasicLineChartOptions from '@/includes/logic/BasicLineChartOptions.js'
import { RecordCard } from '@/components/StatisticsRecordsCards/RecordCard.types'
import StackedChart from '@/includes/logic/StackedChart.js'

import Vue from 'vue'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'
import moment from 'moment'

@Component
export default class ChannelStatisticsMixin extends Vue {

    statisticsLoaded = false

    prepareData(data: any, fromDate: string, toDate: string): any {
        const resObject: Array<any> = []

        const nativeMetrics = cloneDeep([ {
            title: 'native_all_enter',
            value: data.native_all_enter,
        }, {
            title: 'native_all_leave',
            value: data.native_all_leave
        } ])
        const subscribersStatistics: Array<any> = cloneDeep(data.subscribers)
        const postStatistics = cloneDeep(data.posts)

        const xAxisLabel = this.getTimeTicks(fromDate, toDate)

        const timeTicks: Array<string> = []
        const dataTicks: Array<number> = []
        const from = moment(fromDate)
        const to = moment(toDate)

        while (!from.isAfter(to)) {
            timeTicks.push(from.format('YYYY-MM-DDTHH:mm'))
            dataTicks.push(0)
            from.add(1, 'day')
        }

        const all_enter = {
            name: this.$t('channel_subscribers_all_enter'),
            data: cloneDeep(dataTicks),
        }
        const all_leave = {
            name: this.$t('channel_subscribers_all_leave'),
            data: cloneDeep(dataTicks),
        }

        if (subscribersStatistics) {
            subscribersStatistics.map(record => {
                if (timeTicks.includes(record.date)) {
                    const index = timeTicks.indexOf(record.date)
                    if (record.all_enter) {
                        all_enter.data[index] += record.all_enter
                    }
                    if (record.all_leave) {
                        all_leave.data[index] += record.all_leave
                    }
                }
            })
        }

        const all_posts = {
            name: this.$t('channel_posts_all_posts'),
            type: 'column',
            data: cloneDeep(dataTicks),
        }
        const ad_posts = {
            name: this.$t('channel_posts_ad_posts'),
            type: 'column',
            data: cloneDeep(dataTicks),
        }
        const views12 = {
            name: this.$t('channel_posts_views12'),
            type: 'line',
            data: cloneDeep(dataTicks),
        }
        const fwd12 = {
            name: this.$t('channel_posts_fwd12'),
            type: 'line',
            data: cloneDeep(dataTicks),
        }
        const comments = {
            name: this.$t('channel_posts_comments'),
            type: 'line',
            data: cloneDeep(dataTicks),
        }
        const ad_price_sum = {
            name: this.$t('channel_posts_ad_price_sum'),
            type: 'line',
            data: cloneDeep(dataTicks),
        }

        if (postStatistics) {
            postStatistics.map(record => {
                const formattedDate = moment(record.date).format('YYYY-MM-DDTHH:mm')

                if (timeTicks.includes(formattedDate)) {
                    const index = timeTicks.indexOf(formattedDate)
                    if (record.posts) {
                        all_posts.data[index] += record.posts
                    }
                    if (record.ad_posts) {
                        ad_posts.data[index] += record.ad_posts
                    }
                    if (record.views12) {
                        views12.data[index] += record.views12
                    }
                    if (record.fwd12) {
                        fwd12.data[index] += record.fwd12
                    }
                    if (record.comments) {
                        comments.data[index] += record.comments
                    }
                    if (record.ad_price_sum) {
                        ad_price_sum.data[index] += record.ad_price_sum
                    }

                }
            })
        }

        const nativeMetricsProcessed = nativeMetrics.map(this.setFieldsToMetric)

        resObject.push({
            native: nativeMetricsProcessed,
            subscribers: {
                all: {
                    options: this.subscribersChart(xAxisLabel),
                    series: [
                        all_enter,
                        all_leave
                    ]
                }
            },
            posts: {
                posts: {
                    options: this.postChart(xAxisLabel),
                    series: [
                        all_posts,
                        ad_posts,
                    ]
                },
                views: {
                    options: this.viewsChart(xAxisLabel),
                    series: [
                        views12,
                    ]
                },
                data: {
                    options: this.dataMetricsChart(xAxisLabel),
                    series: [
                        fwd12,
                        comments,
                    ]
                }
            }
        })

        this.statisticsLoaded = true
        return resObject
    }

    viewsChart(xLabels: Array<string>): any {
        const subscribers_chart = cloneDeep(BasicLineChartOptions)
        subscribers_chart.title = { text: this.$t('views_chart_stat_title') }
        subscribers_chart.labels = subscribers_chart.labels = xLabels
        subscribers_chart.colors = [ '#008FFB' ]

        return subscribers_chart
    }

    subscribersChart(xLabels: Array<string>): any {
        const subscribers_chart = cloneDeep(BasicLineChartOptions)
        subscribers_chart.title = { text: this.$t('subscribers_chart_stat_title') }
        subscribers_chart.labels = subscribers_chart.labels = xLabels
        subscribers_chart.colors = [ '#00a71f', '#ff4757' ]

        return subscribers_chart
    }

    postChart(xLabels: Array<string>): any {
        const post_chart = cloneDeep(BasicLineChartOptions)
        post_chart.title = { text: this.$t('post_post_stat_title') }
        post_chart.labels = post_chart.labels = xLabels
        post_chart.colors = [ '#008FFB', '#00a71f' ]

        return post_chart
    }

    dataMetricsChart(xLabels: Array<string>): any {
        const chartOptions = new StackedChart(this.$t('post_data_stat_title').toString())
        chartOptions.xAxisLabels = xLabels

        const options = chartOptions.options

        options.colors = [ '#00a71f', '#fdcf2b', '#ff861d' ]
        options.legend.position = 'bottom'
        options.stroke = {
            width: [ 2, 2, 2 ],
            curve: 'smooth',
            dashArray: [ 0, 0, 3 ],
        }

        return options
    }

    getTimeTicks(fromDate: string, toDate: string): Array<string> {
        const from = moment(fromDate)
        const to = moment(toDate)

        const timeTicks: Array<string> = []

        while (!from.isAfter(to)) {
            timeTicks.push(from.format('YYYY-MM-DD').toLocaleLowerCase())
            from.add(1, 'day')
        }
        return timeTicks
    }

    setFieldsToMetric(metric): RecordCard | undefined {
        const commonFields = {
            ...metric,
            value: Number(metric.value).toFixed(2),
        }

        if (metric.title === 'native_all_enter') {
            return {
                ...commonFields,
                color: 'var(--a-success)',
                icon: 'UserPlusIcon',
            }
        }
        if (metric.title === 'native_all_leave') {
            return {
                ...commonFields,
                color: 'var(--a-danger)',
                icon: 'UserMinusIcon',
            }
        }
    }
}