export default {
    chart: {
        toolbar: {
            show: true,
            tools: {
                zoom: true,
                zoomin: true,
                zoomout: true,
                pan: true,
                reset: true,
                download: false,
            }
        },
        height: 350,
        type: 'line',
        zoom: {
            enabled: true
        },
        animations: {
            enabled: false,
        },
    },
    dataLabels: {
        enabled: false,
        style: {
            fontSize: '8px',
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            fontWeight: 400,
        }
    },
    stroke: {
        curve: 'smooth'
    },
    grid: {
        row: {
            colors: [ '#f3f3f3', 'transparent' ], // takes an array which will be repeated on columns
            opacity: 0.5
        },
    },
    xaxis: {
        type: "datetime",
        labels: {
            show: true,
            style: {
                fontSize: '8px',
                fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
                fontWeight: 400,
            },
        },
    },
    yaxis: {
        labels: {
            style: {
                fontSize: '8px',
                fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
                fontWeight: 400,
            },
            formatter: (value) => parseInt(value),
        },
    },
    tooltip: {
        x: { show: false },
        style: {
            fontSize: '12px',
            fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
            fontWeight: 400,
        }
    },
    legend: {
        fontSize: '12px',
        fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
        horizontalAlign: 'left',
        itemMargin: {
            vertical: 5,
        }
    }
}
