import { Target } from '@/includes/types/Board.types'
import moment from 'moment'

export default class ChannelLicense {

    channel:Target

    static LICENSE_TYPES = {
        '0': '',
        '1': 'Business'
    }

    constructor(channel:Target) {
        this.channel = channel
    }

    get licenseType() {
        return ChannelLicense.LICENSE_TYPES[this.channel.license_type]
    }

    get licenseTime() {
        return moment(this.channel.license_time).format('DD.MM.YYYY HH:mm:ss')
    }

    get isLicenseExpired() {
        return moment().isAfter(this.channel.license_time)
    }
}