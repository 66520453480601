






















































































































































































































import { Board, BoardPermissions, DetailedStat, Target, TestChannelStateCode } from '@/includes/types/Board.types'
import ChannelCard from '@/components/ChannelCard.vue'
import { BoardService } from '@/includes/services/BoardService'
import PageTitle from '@/components/PageTitle.vue'
import ChannelStatisticsMixin from '@/mixins/statistics/Channel/ChannelStatisticsMixin'
import { InputSetups } from '@/mixins/input-setups'
import TestStatusInfo from '@/components/TestStatusInfo.vue'
import EmptyBoardsInfo from '@/components/EmptyBoardsInfo.vue'
import TestStateMixin from '@/mixins/TestStateMixin'
import { errorNotification } from '@/includes/services/NotificationService'
import { PostType } from '@/includes/types/Post.types'
import PostTypePicker from '@/components/Post/PostTypePicker.vue'
import AddEntityCard from '@/components/AddEntityCard.vue'
import {
  hasChangeConfigPermission,
  createPostButtonTitle,
  canCreatePost,
  canViewOtherPosts
} from '@/includes/PermissionHelper'
import { CalendarPostsSource } from '@/includes/types/CalendarPostsSource'
import SetBotTokenToBoard from '../components/SetBotTokenToBoard/SetBotTokenToBoard.vue'
import { ADD_BOARD_INSTRUCTION_HASH } from '@/includes/CONSTANTS'
import { BaseItemViewAction } from '@/includes/types/BaseItemViewAction'

import { UseFields } from 'piramis-base-components/src/components/Pi'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import { Mixins } from 'vue-property-decorator'
import Component from 'vue-class-component'
import moment from 'moment'

@Component({
  data() {
    return {
      hasChangeConfigPermission,
      createPostButtonTitle
    }
  },
  components: {
    AddEntityCard,
    EmptyBoardsInfo,
    TestStatusInfo,
    ChannelCard,
    PageTitle,
    EmptyData,
    PostTypePicker,
    SetBotTokenToBoard,
  }
})
export default class Boards extends Mixins<UseFields, InputSetups, ChannelStatisticsMixin, TestStateMixin>(UseFields, InputSetups, ChannelStatisticsMixin, TestStateMixin) {
  targetsMetrics: Record<number, any> = {}

  selectedBoardId: string | null = null

  boards: Array<Board> = []

  isMounted = false

  boardLoading = false

  isPostTypeModalOpen = false

  fullBoardId: string | null = null

  activePermissions: Array<BoardPermissions> | null = null

  activeChannel:string | null = null

  botAddModalOpen = false

  handleGotoPlannerPage(board: Board): void {
    let query = {
      calendar: CalendarPostsSource.Schedule
    } as Record<string, string>

    if (!canCreatePost(board.permissions)) {
      query.calendar = CalendarPostsSource.Suggested
    }

    this.boardRoute('posts_planner', board.board.split('.')[0], query)
  }

  handleModalClose(): void {
    this.fullBoardId = null
    this.activePermissions = null
    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)
  }

  handleCreateNewPostButtonClick(board: Board): void {
    this.isPostTypeModalOpen = true
    this.fullBoardId = board.board
    this.activePermissions = board.permissions

    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', board.board)
  }

  handlePlanPostClick(data: { channelId: number, boardId: string, permissions: Array<BoardPermissions> | null }):void {
    this.activeChannel = data.channelId.toString()
    this.fullBoardId = data.boardId
    this.activePermissions = data.permissions

    this.isPostTypeModalOpen = true
    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', data.boardId)
  }

  handleTemplateButtonClick(boardId:string) {
    this.$router.push({
      name: 'Style',
      params: {
        id: boardId.split('.')[0],
        actionType: BaseItemViewAction.New
      }
    })
  }

  get sortedBoards(): Array<Board> {
    return this.boards.sort((a: Board, b: Board) => {
      if (a.targets.length > b.targets.length || a.weight > b.weight) {
        return -1
      }
      if (a.targets.length < b.targets.length || a.weight < b.weight) {
        return 1
      }
      return 0
    })
  }

  postTypeSelectHandler(data: { type: PostType, query: Record<string, any> | null }) {
    this.$store.commit('AdminTemplate/SET_ACCOUNT_DATA_ROOT', null)

    this.$router.push({
      name: 'post',
      params: {
        id: this.fullBoardId?.split('.')[0] as string,
        actionType: 'new',
      },
      query: {
        type: data.type.toLowerCase(),
        ...data.query

      },
    })
  }

  updateChannelState(channelInfo: { state: TestChannelStateCode, channelId: number, boardId: string }): void {
    this.boards = this.boards.slice().map(b => {
      if (b.board === channelInfo.boardId) {
        const board = b
        board.targets = board.targets.map(t => {
          if (t.id === channelInfo.channelId) {
            return {
              ...t,
              test_state: channelInfo.state
            }
          }
          return t
        })
        return board
      }
      return b
    })
  }

  boardRoute(routeName: string, board: string, query?: Record<string, string>): void {
    this.$router.push({
      name: routeName,
      params: {
        id: board
      },
      query: { ...query }
    })
  }

  sortChannelsByMembersCount(targets: Array<Target>): Array<Target> {
    return targets.slice().sort((a: Target, b: Target) => b.members_count - a.members_count)
  }

  async requestBoardsStat(): Promise<void> {
    try {
      const targetStatistics: Array<{ subscribers: Array<Pick<DetailedStat, 'all_enter' | 'all_leave' | 'channel_id' | 'time'>> }> = []
      const { boards } = await BoardService.getBoardsStat('tg', {})

      boards.forEach((board: Board) => {
        board.targets.forEach((target: Target) => {
          if (target.detailed_stat.length) {
            targetStatistics.push({
              subscribers: [ ...target.detailed_stat ]
            })
          } else {
            targetStatistics.push({
              subscribers: [ {
                all_enter: 0,
                all_leave: 0,
                channel_id: target.id,
                time: ''
              } ]
            })
          }
        })
      })
      const preparedTargetStatistics = targetStatistics.map(value => {
        return {
          subscribers: value.subscribers.map(s => {
            return {
              all_leave: s.all_leave,
              all_enter: s.all_enter,
              date: s.time,
              channel_id: s.channel_id
            }
          })
        }
      })

      preparedTargetStatistics.forEach(t => {
        this.$set(this.targetsMetrics, t.subscribers[0].channel_id, this.prepareData(t, moment().add(-7, 'days').startOf('day').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')))
      })
    } catch (error: any) {
      errorNotification(error)
    }
  }

  onChannelStateClick(board: Board): void {
    this.handleOnChannelStateClick({ state: board.test_state, entity: 'board' })
    this.selectedBoardId = board.board.split('.')[0]
  }

  mounted(): void {
    this.boardLoading = true
    this.boards = this.$store.state.boardsState.boards
    this.boardLoading = false

    this.requestBoardsStat()

    this.isMounted = true
  }

  created():void {
    if (this.$route.hash === ADD_BOARD_INSTRUCTION_HASH) {
      this.botAddModalOpen = true
    }
  }

}
