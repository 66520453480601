import i18n  from "../../i18n/i18n";
import store from "../../store/store";

/**
 * @interface OptionsBase
 */
export default class OptionsBase {
	
	optionsSample;
	_options;
	
	setLocaleSettings() {
		this._options.chart.defaultLocale = i18n.locale;
		this._options.chart.locales       = store.state.statisticsState.locales;
	}
	
	/**
	 *
	 * @param val {Array<Any>}
	 */
	set xAxisLabels(val) {
		this._options.xaxis = this._generateXAxis(val);
	}
	
	/**
	 *
	 * @param xAxisLabels {Array<Any>}
	 * @private
	 * @return {Object}
	 */
	_generateXAxis(xAxisLabels) {
		return {
			type         : "datetime",
			tickPlacement: "on",
			categories   : xAxisLabels,
			// axisBorder: {
			//   show : true,
			//   color: "#008FFB",
			// },
			// labels    : {
			//   style: {
			//     color: "#008FFB",
			//   },
			// },
		};
	}
	
	/**
	 *
	 * @return {Object}
	 */
	get options() {
		return this._options;
	}
}
