



































































































































































import { BoardPermissions, Target, TargetState, TestChannelStateCode } from '@/includes/types/Board.types'
import ChannelLicense from '@/includes/logic/ChannelLicense'
import channelStatusInfo from '@/components/TestStatusHelpInfo/Channel'
import TestStatusInfo from '@/components/TestStatusInfo.vue'
import { hasChangeConfigPermission } from '@/includes/PermissionHelper'
import DoPostSvgIcon from '@/components/svg/DoPostSvgIcon.vue'
import TestStateMixin from '@/mixins/TestStateMixin'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { snakeCase } from 'lodash'
import { Emit, Mixins, Prop } from 'vue-property-decorator'
import Component from 'vue-class-component'
import VueApexCharts from 'vue-apexcharts'
import moment from 'moment'

@Component({
  components: {
    VueApexCharts,
    TestStatusInfo,
  },
  data() {
    return {
      channelStatusInfo,
      snakeCase,
      hasChangeConfigPermission,
      DoPostSvgIcon,
      moment,
    }
  }
})
export default class ChannelCard extends Mixins<UseFields, TestStateMixin>(UseFields, TestStateMixin) {
  @Prop({ required: true, type: Object }) channel !: Target

  @Prop({ required: false, type: String }) boardKey!: string

  @Prop({ type: Boolean }) changeStateButton!: boolean

  @Prop({ type: Boolean, default: false }) postButton!: boolean

  @Prop({ type: Array, default: null }) permissions!: Array<BoardPermissions> | null

  @Prop() statistics!: any

  @Emit('onChannelStateClick')
  onChannelStateClick(state: TestChannelStateCode, entity: 'channel' | 'board'): { state: TestChannelStateCode, entity: 'channel' | 'board' } {
    return { state, entity }
  }

  @Emit('onChannelStateButtonClick')
  onChannelStateButtonClick(): number {
    return this.channel.id
  }

  @Emit('onChannelPlanPostCLick')
  onChannelPlanPostCLick(): { channelId: number, boardId: string, permissions: Array<BoardPermissions> | null } {
    return { channelId: this.channel.id, boardId: this.boardKey, permissions: this.permissions }
  }

  notPrincipledStatus = [
    TestChannelStateCode.ChannelPermissionAddUsersError,
    TestChannelStateCode.ConnectedGroupNotFoundError,
    TestChannelStateCode.ConnectedGroupPermissionSendMessageError,
    TestChannelStateCode.ConnectedGroupPermissionPinMessageError,
    TestChannelStateCode.ConnectedGroupPermissionDeleteMessageError,
    TestChannelStateCode.ConnectedGroupPermissionEditMessageError,
  ]

  status: TestChannelStateCode | null = null

  TargetState: any = TargetState

  chartOptions = {
    grid: {
      show: false,
      padding: {
        top: 10,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
    chart: {
      animations: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2.5,
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.9,
        opacityFrom: 0.7,
        opacityTo: 0,
        stops: [ 0, 100 ],
      },
    },
    xaxis: {
      lines: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      labels: { show: false },
    },
    yaxis: {
      labels: {
        style: {
          fontSize: '8px',
          fontFamily: '"Montserrat", Helvetica, Arial, sans-serif',
          fontWeight: 400,
        },
        formatter: (value) => parseInt(value),
      },
    },
    tooltip: {
      x: { show: false },
    },
  }

  get channelLicense(): ChannelLicense {
    return new ChannelLicense(this.channel)
  }

  get tooltipTitle():string {
    if (this.channel.free_licence && !this.channel.licence_expired) {
      return `${ this.$t('license_expires').toString() }: ${ this.channelLicense.licenseTime }`
    } else if (this.channel.free_licence && this.channel.members_count < 1000) {
      return this.$t('free_license_under_1000').toString()
    } else if (this.channel.free_licence && this.channel.members_count > 1000) {
      return this.$t('free_license_test_period').toString()
    } else if (!this.channel.licence_expired) {
      return `${ this.$t('license_expires').toString() }: ${ this.channelLicense.licenseTime }`
    } else {
      return this.$t('license_expired').toString()
    }
  }

  get buyLicenseButtonTitle():string {
    if (this.channel.licence_expired) {
      return this.$t("license_buy").toString()
    } else {
      return this.$t('button_license_prolongate').toString()
    }
  }

  get boardParamKey(): string {
    return this.boardKey.split('.')[0]
  }

  gotoRoute(routeName: string): void {
    this.$router.push({
      name: routeName,
      params: {
        id: this.boardParamKey,
        channelId: this.channel.id.toString()
      },
    })
  }

  handleTestChannelStatusClick(event: Event): void {
    if (this.buttonDisabled) {
      event.preventDefault()
    } else {
      this.testChannelStatus(this.boardKey, this.channel.id)
    }
  }

  destroyed(): void {
    this.$store.commit('resetTestStateCounter')
  }

  mounted(): void {
    if (this.boardKey) {
      if (this.channel.test_state === 'None' && this.$store.state.testStateCounter < 3) {
        this.$store.commit('updateTestStateCounter')
        this.testChannelStatus(this.boardKey, this.channel.id)
      }
    }
  }
}

